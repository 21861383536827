import React from "react"
import {
  useStaticQuery,
  graphql,
  // Link
} from "gatsby"
// import { Icon } from "../components/icons"

import {
  // CaseStudy,
  // ClientList,
  // FeatureCard,
  Layout,
  // PageHeader,
  // PageSection,
  SEO,
  // Spiel,
} from "../components"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexPageQuery {
      site {
        siteMetadata {
          introTitle
          tagline
          siteDescription
          landingPage {
            services {
              title
              body
            }
          }
          clients {
            logo
            name
          }
        }
      }
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/work/" }
          frontmatter: { landing: { eq: true } }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            slug
            title
            dates
            date(formatString: "MMMM DD, YYYY")
            technologies
            location
            landing
          }
          excerpt(pruneLength: 300, format: HTML)
        }
      }
    }
  `)

  const {
    siteDescription,
    // tagline,
    // introTitle,
    // landingPage,
    // clients,
  } = data.site.siteMetadata
  // const { services } = landingPage
  // const caseStudies = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <>
        <SEO title={siteDescription} />
        {/* <PageHeader scrollIcon={true} title={introTitle} subtitle={tagline} /> */}

        {/* <PageSection theme="light" id="main-content">
          <Spiel />
        </PageSection> */}

        {/* <PageSection
          id="services-availability"
          theme="mid"
          title="Services and availability"
        >
          <div className="content content--compact">
            <p>
              With over 10 years of experience in coding, I have now refined my
              skill set and concentrate on improving standards, quality, and
              communication within teams.
            </p>
            <p>
              While I still get hands-on to code and develop websites and web
              applications my focus is now consulting on technical management
              and helping teams collaborate and work better together to
              streamline workflow and improve delivery.
            </p>
          </div>

          <div className="feature-cards">
            {services.map(service => {
              return (
                <FeatureCard
                  key={service.title}
                  title={service.title}
                  body={service.body}
                />
              )
            })}
          </div>
        </PageSection> */}

        {/* <PageSection
          id="partners-and-friends"
          title="Partners and friends"
          intro="A handful of the amazing businesses and teams I’ve worked with in my career so far."
        >
          <ClientList clients={clients} />
        </PageSection> */}

        {/* <PageSection id="case-studies" theme="mid" title="Case studies">
          <div className="content content--compact">
            <p>
              A selection of my recent experience. These case studies delve into
              the technical detail of the projects and products that I’ve worked
              on, the companies and people that I’ve helped and what I learned
              from the experience.
            </p>

            <Link to="/work" className="page-section__cta">
              view all case studies <Icon name="arrow-right" fill="#B7B7B7" />
            </Link>
          </div>

          <div className="case-studies">
            {caseStudies.map(caseStudy => {
              const { excerpt, frontmatter } = caseStudy
              const { title } = frontmatter

              return (
                <CaseStudy
                  key={title}
                  caseStudy={frontmatter}
                  intro={excerpt}
                />
              )
            })}
          </div>
        </PageSection> */}

        {/* <PageSection
          id="work-with-me"
          theme="dark"
          title="Want to work together?"
        >
          <div className="content  content--compact">
            <p>
              I’m always up for new challenges where I can collaborate with
              people and companies to realise their products. Don’t be a
              stranger, say hello!
            </p>

            <Link to="/contact" className="page-section__cta">
              Get in touch <Icon name="arrow-right" fill="#B7B7B7" />
            </Link>
          </div>
        </PageSection> */}
      </>
    </Layout>
  )
}

export default IndexPage
